"use strict";

import { addFilter } from '@wordpress/hooks';
import './style.scss';
import vixaiControls from "./components/vixaiControls";

addFilter(
	'editor.BlockEdit',
	'vixai/controls',
	vixaiControls,
);

